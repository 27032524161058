import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
  newArrSix,
  newArrSeven
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="Mosquito Mesh arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={newArrOne}
            productName="Solid Door Machar Jalli"
            price="450.00"
            color="Elevate your space with our Solid Door Mosquito Mesh featuring T6 virgin aluminum frames, powerful magnetic catchers, dust resistance, and easy maintenance. Enjoy a bug-free environment effortlessly.."
            badge={true}
            des="Powerful magnetic catchers"
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={newArrThree}
            productName="Ace Door Machar Jalli"
            price="330.00"
            color="T6 aluminum, magnetic catchers, dust-resistant, easy maintenance, black epoxy coating. Effortless style meets effective functionality."
            badge={true}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={newArrFour}
            productName="Pleated Machar Jalli"
            price="290.00"
            color="Elevate your space with our Pleated Mosquito Mesh: T6 virgin aluminum frames, powerful magnetic catchers, easy maintenance, and waterproof design for hassle-free bug protection."
            badge={false}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>

        <div className="px-2">
          <Product
            _id="100004"
            img={newArrFive}
            productName="Roll Up Machar Jalli"
            price="60.00"
            color="Elevate your space with our Pleated Mosquito Mesh – T6 virgin aluminum frames, hydraulic spring, anti-microbial coating, and waterproof. Effortless protection for a bug-free environment."
            badge={false}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>

        <div className="px-2">
          <Product
            _id="100004"
            img={newArrSix}
            productName="Barrier Free Machar Jalli"
            price="60.00"
            color="Sleek and functional, our Barrier-Free Mosquito Mesh boasts T6 virgin aluminum frames, a hydraulic spring mechanism, anti-microbial coating, and a robust foldable nylon runner with epoxy polyester coating. Effortless protection with style."
            badge={false}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>

        <div className="px-2">
          <Product
            _id="100004"
            img={newArrSeven}
            productName="Sleek Machar Jalli Classic"
            price="180.00"
            color="Upgrade your space effortlessly with our Heavy Sliding Mosquito Mesh. T6 virgin aluminum frames ensure durability, while providing easy access, improved visibility, and better ventilation."
            badge={false}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>

        <div className="px-2">
          <Product
            _id="100004"
            img={newArrSeven}
            productName="Sleek Machar Jalli Premium"
            price="280.00"
            color="Upgrade your space effortlessly with our Heavy Sliding Mosquito Mesh. T6 virgin aluminum frames ensure durability, while providing easy access, improved visibility, and better ventilation."
            badge={false}
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          />
        </div>
        
      </Slider>
    </div>
  );
};

export default NewArrivals;
