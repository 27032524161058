import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import ChildSafetyGrill from "../../components/home/ChildSafetyGrill/ChildSafetyGrill";
import CustomerTestimonials from "../../components/CustomerTestimonials/CustomerTestimonials";
import AreasServed from "../../components/AreasServed/AreasServed";
import StatsProjects from "../../components/AboutUsPage/StatsProjects";
import AntiBirdGrill from "../../components/home/AntiBirdGrill/AntiBirdGrill";
const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        <Sale />
        <YearProduct />
        <NewArrivals />
       <ChildSafetyGrill/>
       <AntiBirdGrill/>
       <CustomerTestimonials/>
       <AreasServed/>
       <StatsProjects/>
        
      
      </div>
    </div>
  );
};

export default Home;
