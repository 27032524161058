import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import AboutUsPage from "../../components/AboutUsPage/AboutUsPage";
const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-10">
        <AboutUsPage/>
      
        <h1 className="max-max text-base text-lightText mb-2">
          <span className="text-primeColor font-semibold text-lg">Formula Safety</span>{" "}
          where passion meets precision in the world of interior design. Our journey is rooted in the belief that walls are not just boundaries; they are canvases waiting to be transformed into masterpieces. At Formula Walls, we are not just about wallpapers; we are about crafting experiences, fostering communities, and empowering interior designers to redefine spaces.
        </h1>
        <br></br>
        <Link to="/">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;