import React, { useState,useCallback } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyBjhxBZMilQne2KNKdQD4tjFE9zh3tRvwA",
  authDomain: "formula-walls-pos.firebaseapp.com",
  databaseURL: "https://formula-walls-pos-default-rtdb.firebaseio.com",
  projectId: "formula-walls-pos",
  storageBucket: "formula-walls-pos.appspot.com",
  messagingSenderId: "339130974726",
  appId: "1:339130974726:web:6eeae69105cddc19b57987",
  measurementId: "G-91N427G8C8"
};


const app = initializeApp(firebaseConfig);


const EnquiryForm = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.orebiReducer.products);
  var productArr=[]

  const mappingDictProduct={
    'Mosquito Machar Jalli':1,
    'Pleated Machar Jalli':2,
    'Magnetic Window Machar Jalli':3,
    'Solid Door Machar Jalli':4,
    'Ace Door Machar Jalli':5,
    'Roll Up Machar Jalli':6,
    'Barrier Free Machar Jalli':7,
    'Heavy Sliding Machar Jalli':8,
    'High Raise Child Safety Balcony Grills':9
  }

  const productOptionsTemp = [
    { id: 1, value: 'Mosquito Machar Jalli', label: 'Mosquito Machar Jalli' },
    { id: 2, value: 'Pleated Machar Jalli', label: 'Pleated Machar Jalli' },
    { id: 3, value: 'Magnetic Window Machar Jalli', label: 'Magnetic Window Machar Jalli' },
    { id: 4, value: 'Solid Door Machar Jalli', label: 'Solid Door Machar Jalli' },
    { id: 5, value: 'Ace Door Machar Jalli', label: 'Ace Door Machar Jalli' },
    { id: 6, value: 'Roll Up Machar Jalli', label: 'Roll Up Machar Jalli' },
    { id: 7, value: 'Barrier Free Machar Jalli', label: 'Barrier Free Machar Jalli' },
    { id: 8, value: 'Heavy Sliding Machar Jalli', label: 'Barrier Free Machar Jalli' },
    { id: 9, value: 'High Raise Child Safety Balcony Grills', label: 'High Raise Child Safety Balcony Grills' },
    // Add more product options as needed
  ];
  products.map((item) => (
    productArr.push(productOptionsTemp[mappingDictProduct[item.name]-1])
  ));


  console.log('products selected ',productArr)
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(productArr);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  function getFormattedDate() {
    const today = new Date();
  
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }

  function generateUniqueId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }
  
    return uniqueId;
  }

  const handleProductSelect = (selectedList) => {
    setSelectedProducts(selectedList);
  };

  const handleProductRemove = (selectedList) => {
    setSelectedProducts(selectedList);
  };

  const handleSubmit = (e) => {
    setActive(true);
    e.preventDefault();
    // You can handle the form submission logic here, for example, sending the data to a server.
    console.log('Form submitted:', { name, email, phone, selectedProducts });

    const enquiryId=generateUniqueId(10);
    const enquiryDate=getFormattedDate();

    const db = getDatabase();
     set(ref(db, 'website_enquiries_static/' + enquiryId), {
      username: name,
      email: email,
      phoneNumber : phone,
      enquiryId:enquiryId,
      enquiryDate:enquiryDate,
      selectedProducts:selectedProducts,
      isAcked:false,
      isResponsePostive:false,
      customerComment:'',
      isMarkedForFollowUp:false,
      isClosed:false,
      nextFollowUpId:''

    }).then(() => {
      // Success: Data was successfully set
      console.log('Form submitted:');
      toast.success('Our team will be contacting you soon.', {
        position: toast.POSITION.TOP_CENTER,
      });
      setActive(true);
      // Navigate to the home page
      navigate('/');

    })
    .catch((error) => {
      // Error: Handle the error here
      console.error('Error submitting form:', error.message);
    });

    

  };

  const productOptions = [
    { id: 1, value: 'Mosquito Machar Jalli', label: 'Mosquito Machar Jalli' },
    { id: 2, value: 'Pleated Mosquito Machar Jalli', label: 'Pleated Mosquito Machar Jalli' },
    { id: 3, value: 'Magnetic Window Machar Jalli', label: 'Magnetic Window Machar Jalli' },
    { id: 4, value: 'Solid Door Mosquito Machar Jalli', label: 'Solid Door Mosquito Machar Jalli' },
    { id: 5, value: 'Ace Door Machar Jalli', label: 'Ace Door Machar Jalli' },
    { id: 6, value: 'Roll Up Machar Jalli', label: 'Roll Up Machar Jalli' },
    { id: 7, value: 'Barrier Free Machar Jalli', label: 'Barrier Free Machar Jalli' },
    { id: 8, value: 'Heavy Sliding Machar Jalli', label: 'Barrier Free Machar Jalli' },
    { id: 9, value: 'High Raise Child Safety Balcony Grills', label: 'High Raise Child Safety Balcony Grills' },
    // Add more product options as needed
  ];

  


  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Saving Your Enquiry...Will contact you soon'
    >
    <div className="w-max mx-auto px-4 md:px-11">
      <h2 className="text-3xl md:text-6xl font-bold mb-4">Customer Enquiry Form</h2>
      <form onSubmit={handleSubmit} className="max-w-md">
        <div className="mb-4">
          <label htmlFor="name" className="block text-md font-medium text-gray-600">
            Name:
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-md font-medium text-gray-600">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-md font-medium text-gray-600">
            Phone:
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={handlePhoneChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="products" className="block text-md font-medium text-gray-600">
            Products of Interest:
          </label>
          <Multiselect
            options={productOptions}
            selectedValues={productArr}
            onSelect={handleProductSelect}
            onRemove={handleProductRemove}
            displayValue="label"
            id="products"
            placeholder="Select Products"
            isMulti
          />
        </div>

        <button
          type="submit"
          className="bg-gray-900 text-white p-2 rounded-md hover:bg-gray-500 w-full"
        >
          Submit Enquiry
        </button>
      </form>

      <div className="mt-8">
        <h2 className="text-4xl font-bold mb-4">Contact Us</h2>
        <p>Have questions? Contact us via:</p>
        <a href="mailto:your@email.com" className="text-blue-500 hover:underline flex items-center mt-2 mb-7">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          Email Us
        </a>
        <a href="https://wa.me/1234567890" className="text-green-500 hover:underline flex items-center mt-2 mb-7">
          <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
          WhatsApp Us
        </a>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

    </div>

    </LoadingOverlay>
  );
};

export default EnquiryForm;
