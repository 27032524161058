import React from "react";
import { logo } from "../../assets/images/index";
import Image from "../designLayouts/Image";
import StatsProjects from "./StatsProjects";
const AboutUsPage = () => {
  return (
    <div>
      <div className="w-full pb-16 flex  justify-center">
        <Image imgSrc={logo} className="align-middle" />
      </div>
      <StatsProjects />
    </div>
  );
};

export default AboutUsPage;
