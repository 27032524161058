import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

const StatsElement = (props) => {
  return (
    <div class="mx-auto flex max-w-xs flex-col gap-y-0 p-12 bg-white rounded-xl shadow-xl">
      <dt class="text-base leading-7 text-gray-600">{props.subText}</dt>
      <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
      <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
        {({ isVisible }) => (
          <div style={{ height: 100 }}>
            {isVisible ? <CountUp end={props.countMax} duration={2}/>: null}+
          </div>
        )}
      </VisibilitySensor>
      </dd>
    </div>
  );
};

export default StatsElement;
