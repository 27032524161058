import React from "react";
import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";

import Image from "../../designLayouts/Image";

const YearProduct = () => {
  return (
    <Link to="/">
      <div className="w-full h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
        <Image
          className="w-full h-full object-cover hidden md:inline-block"
          imgSrc={productOfTheYear}
        />
        <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
          <h1 className="text-3xl font-semibold text-primeColor">
            Formula Safety
          </h1>
          <p  className="text-base font-bold text-lightText max-w-[600px] mr-2">
            Beyond boundaries. Above Worries.
          </p>

          <h2  className="text-base font-normal text-primeColor max-w-[600px] mr-4">
          At Formula Safety, we are dedicated to redefining the concept of safety in homes. Specializing in high-grade child safety solutions, our invisible grills create a protective barrier without compromising the view, ensuring your little ones can explore and play safely. Our commitment extends to offering mosquito meshes, transforming living spaces into secure havens. With Formula Safety, embrace a harmonious blend of aesthetics and security, where peace of mind is woven into every detail.          </h2>
          
        </div>
      </div>
    </Link>
  );
};

export default YearProduct;
