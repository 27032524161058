import React from "react";
import { Link } from "react-router-dom";
import { childSafetyBalconyGrill } from "../../../assets/images";

import Image from "../../designLayouts/Image";

const ChildSafetyGrill = () => {
  return (
    <Link to="/enquiry">
      <div className="w-full h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
        <Image
          className="w-full h-full object-cover hidden md:inline-block"
          imgSrc={childSafetyBalconyGrill}
        />
        <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
          <h1 className="text-3xl font-semibold text-primeColor">
          Formula High-Rise Child Safety Balcony Grills
          </h1>
          

          <h2  className="text-base font-normal text-primeColor max-w-[600px] mr-4">
          Ensuring unparalleled safety, these grills feature robust construction, precisely spaced bars, and an aesthetic appeal. Prioritizing your child's safety, they act as a vital barrier against balcony hazards, providing peace of mind. Backed by a solid warranty, Formula Walls guarantees durability and offers free installation, ensuring an unmatched customer experience.</h2>
          <h2 className="text-bold font-bold  text-2xl text-primeColor max-w-[600px] mr-4">Starting From ₹ 170/sq.ft</h2>
        </div>
      </div>
    </Link>
  );
};

export default ChildSafetyGrill;
