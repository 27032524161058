import React from "react";
import { Link } from "react-router-dom";
import { nettingSolutions } from "../../../assets/images";

import Image from "../../designLayouts/Image";

const AntiBirdGrill = (props) => {
  return (
    <Link to="/enquiry" className="bg-black">
      <div className="w-full h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
        <Image
          className="w-full h-full object-cover hidden md:inline-block"
          imgSrc={nettingSolutions}
        />
        <div className="w-full md:w-2/3 xl:w-1/2 min-h-full absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center ">
          <h1 className="text-2xl font-semibold text-primeColor">
            Formula High-Rise Anti Bird Grills
          </h1>

          <h2 className="text-md font-normal text-primeColor max-w-[600px] mr-4">
            Formula Anti-Bird Mosquito Meshes provide an innovative solution for
            safeguarding your space against unwanted pests. These meshes are
            meticulously designed to effectively block both birds and
            mosquitoes, ensuring a peaceful and hygienic environment. 
          </h2>
          <h2 className="text-bold font-bold  text-2xl text-primeColor max-w-[600px] mr-4">
            Starting From ₹ 240/sq.ft
          </h2>
        </div>
      </div>
    </Link>
  );
};

export default AntiBirdGrill;
