import React from "react";
import CountUp from "react-countup";
import StatsElement from "./StatsElement";

const StatsProjects = (props) => {
  return (
    <div className="bg-gray-100 m-8 rounded-xl">
        <div className="justify-center ">
        <h2 className=" text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center py-4">Our Footprint</h2>
        </div>
      <div class="py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3 bg-gray-100">
            <StatsElement subText={"of Experience"} countMax={12} />
            <StatsElement subText={"Projects Completed"} countMax={150} />
            <StatsElement subText={"Areas Covered"} countMax={50} />
          </dl>
        </div>
      </div>
    </div>
  );
};

export default StatsProjects;
